import type { RangePickerProps } from 'antd/es/date-picker'
import DatePicker from 'antd/es/date-picker'
import type { FormItemProps } from 'antd/es/form'
import Form from 'antd/es/form'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import type { DateRangeType } from 'firebird-ui/src/types/datePicker/customInput'
import { useTranslation } from 'next-i18next'
import type { FC, MouseEvent, ReactNode } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { datePickerButtons } from '@base/DatePicker'
import ClearReturnDateButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/ClearReturnDateButton'
import MobileHeader from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/mobileHeader/MobileHeader'
import {
  datePikerLabelClassName,
  datePikerPopupClassName,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  formHeightDesktop,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import SaveButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/saveButton/SaveButton'
import { changeDirection } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/changeDirection'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import dateFormats from '@constants/dates/dateFormats'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import useToggle from '@hooks/useToggle/useToggle'
import { mediaQueries } from '@themes/mediaQueries'
import confirmButtonClickHandler from '@utils/datePicker/confirmButtonClickHandler'
import getIsDepartureInputActive from '@utils/datePicker/getIsDepartureInputActive'
import getSearchFormPickerInputs from '@utils/datePicker/getSearchFormPickerInputs'
import getSearchFormPickerInputsContainers from '@utils/datePicker/getSearchFormPickerInputsContainers'
import { isPastDay, today } from '@utils/dateTime/dateValidators'

import { CalenderIcon, DateInputGlobalStyles, DateLabel, WrapperDateInput } from './styles'

dayjs.extend(weekday)
dayjs.extend(localeData)

const ALLOW_EMPTY: RangePickerProps['allowEmpty'] = [false, true]

type Props = {
  close: () => void
  compactLabelView?: boolean
  datePickerProps?: RangePickerProps
  departureLabel?: string
  formItemsProps?: FormItemProps
  isOpened: boolean
  onClearReturnDate?: () => void
  open: () => void
  placeholder?: [string, string]
  position: 'bottom' | 'right'
  returnLabel?: string
}

export const DateInput: FC<Props> = ({
  close,
  compactLabelView,
  datePickerProps,
  departureLabel,
  formItemsProps,
  isOpened,
  onClearReturnDate,
  open,
  placeholder,
  position,
  returnLabel,
}) => {
  const form = Form.useFormInstance<SearchFormValuesType>()
  const [activeInput, setActiveInput] = useState<DateRangeType>()
  const [tempValue, setTempValue] = useState<SearchFormValuesType[SearchFormKeys.date]>(() =>
    form.getFieldValue(SearchFormKeys.date)
  )
  const { on: isLockOpened, toggleOff: unLockOpened, toggleOn: lockOpened } = useToggle()
  const { t } = useTranslation('Search form')
  const isMobileTablet = useMediaQuery(mediaQueries.mobileTablet)
  const departureCaptionText = departureLabel || t('departureDate')
  const returnCaptionText = returnLabel || t('returnDate')
  const departurePlaceholder = `+${t('departure')}`
  const returnPlaceholder = `+ ${t('addReturn')}`
  const isMobile = useIsMobile()

  const popupClassName = `${datePikerPopupClassName} ${position}`

  const onClickInput = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const range = (e.target as HTMLInputElement)?.getAttribute('date-range') as DateRangeType
      const dates = form.getFieldValue(SearchFormKeys.date)

      if (range === 'end' && !dates?.[1] && !isMobile) {
        form.setFieldValue(SearchFormKeys.date, [dates[0], dates[0]])
      }

      if (activeInput === range && isOpened) {
        close()
      } else {
        setActiveInput(range)
      }
    },
    [isOpened, activeInput, isMobile]
  )

  const onOpenChange = useCallback(
    (value: boolean) => {
      if (value) {
        open()
        lockOpened()
        setTempValue(form.getFieldValue(SearchFormKeys.date) as SearchFormValuesType[SearchFormKeys.date])
      } else if (!isMobile || (isMobile && !isLockOpened)) {
        close()
        unLockOpened()
      }
    },
    [close, open, isLockOpened, isMobile]
  )

  const onSave = useCallback((e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    const isDepartureActive = getIsDepartureInputActive(getSearchFormPickerInputsContainers())
    const [inputDeparture, inputReturn] = getSearchFormPickerInputs()

    const handleDateUpdate = () => {
      form.setFieldValue(SearchFormKeys.date, [
        dayjs(inputDeparture.value),
        inputReturn.value ? dayjs(inputReturn.value) : null,
      ])
    }

    if (isDepartureActive) {
      if (dayjs(inputDeparture.value).isAfter(dayjs(inputReturn.value))) {
        handleDateUpdate()
        changeDirection()
      } else {
        handleDateUpdate()
        confirmButtonClickHandler(e)
        close()
        unLockOpened()
      }
    } else {
      if (dayjs(inputReturn.value).isSameOrAfter(dayjs(inputDeparture.value))) {
        handleDateUpdate()
        confirmButtonClickHandler(e)
      } else {
        form.setFieldValue(SearchFormKeys.date, [
          dayjs(inputReturn.value || inputDeparture.value),
          dayjs(inputReturn.value || inputDeparture.value),
        ])
      }
      close()
      unLockOpened()
    }
  }, [])

  const onPanelClickHandler = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      const cell = (e.target as Element).closest('.ant-picker-cell') as HTMLDivElement
      const newDate = cell?.getAttribute('title')
      const dayjsNewDate = dayjs(newDate)
      const isDepartureActive = getIsDepartureInputActive(getSearchFormPickerInputsContainers())
      const [inputDeparture, inputReturn] = getSearchFormPickerInputs()

      const handleDateUpdate = () => {
        setTimeout(() => {
          form.setFieldValue(SearchFormKeys.date, [
            isDepartureActive ? dayjsNewDate : dayjs(inputDeparture.value),
            !isDepartureActive ? dayjsNewDate : inputReturn.value ? dayjs(inputReturn.value) : null,
          ])
        })
        close()
      }

      if (newDate) {
        if (isDepartureActive) {
          if (dayjs(newDate).isAfter(dayjs(inputReturn.value))) {
            inputReturn.focus()
          } else {
            handleDateUpdate()
          }
        } else {
          if (dayjs(newDate).isSameOrAfter(dayjs(inputDeparture.value))) {
            handleDateUpdate()
          } else {
            form.setFieldValue(SearchFormKeys.date, [dayjsNewDate, dayjs(inputDeparture.value)])
            setTimeout(() => {
              inputReturn.value = inputDeparture.value
              inputDeparture.value = dayjsNewDate.format(dateFormats['01 Jan 2023'])
            }, 100)
          }
        }
      }
    },
    [isMobile, form, close]
  )

  const onPanelClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (!isMobile) setTimeout(() => onPanelClickHandler(e), 0)
  }

  const panelRender = useCallback(
    (panel: ReactNode) => (
      <>
        {isMobile && (
          <MobileHeader
            closeAction={() => {
              close()
              //https://firebird.atlassian.net/browse/ITOP-16595
              //I think it antd 5.18.0 RangePicker needConfirm bug https://codepen.io/Sergei-firebird/pen/PovJoRV?editors=001
              setTimeout(() => {
                form.setFieldValue(SearchFormKeys.date, tempValue)
              }, 0)
            }}
          />
        )}
        <div onClick={onPanelClick} style={{ height: 'inherit', width: 'inherit' }}>
          {panel}
        </div>
      </>
    ),
    [isMobile, onPanelClick, close, tempValue]
  )

  const renderExtraFooter = useCallback(() => <SaveButton onClick={onSave} />, [])

  const style = useMemo(
    () => ({
      height: `${isMobile ? searchFormSizes.fieldHeightMobile : searchFormSizes.fieldHeightDesktop}px`,
      padding: 0,
      width: '100%',
    }),
    [isMobile]
  )

  const popupStyle = useMemo(
    () => ({
      height: `${isMobile ? '100%' : `${formHeightDesktop + searchFormSizes.layoversHeightDesktop}px`}`,
      padding: 0,
      position: isMobile ? 'fixed' : 'absolute',
      width: `${
        isMobile
          ? '100%'
          : isMobileTablet
          ? `${searchFormSizes.popupWidthTablet}px`
          : `${searchFormSizes.popupWidthDesktop}px`
      }`,
      ...datePickerProps?.popupStyle,
    }),
    [isMobile, isMobileTablet, datePickerProps?.popupStyle]
  )

  const suffixIcon = useMemo(() => (compactLabelView ? null : <CalenderIcon />), [compactLabelView])

  return (
    <WrapperDateInput>
      <DateLabel
        $compactLabelView={compactLabelView}
        $departureLabel={departureCaptionText}
        $isPopoverOpened={isOpened}
        $returnLabel={returnCaptionText}
        className={datePikerLabelClassName}
        name={SearchFormKeys.date}
        rules={[{ required: true }]}
        {...(formItemsProps || {})}
      >
        <DatePicker.RangePicker
          {...datePickerButtons}
          allowClear={false}
          allowEmpty={ALLOW_EMPTY}
          autoFocus={true}
          clearIcon={false}
          disabledDate={isPastDay}
          format={dateFormats['01 Jan 2023']}
          getPopupContainer={node => node}
          inputReadOnly={true}
          minDate={today}
          needConfirm
          onClick={onClickInput}
          onOpenChange={onOpenChange}
          open={isOpened}
          panelRender={panelRender}
          placeholder={placeholder ? placeholder : [departurePlaceholder, returnPlaceholder]}
          placement="bottomLeft"
          popupClassName={popupClassName}
          renderExtraFooter={renderExtraFooter}
          separator={null}
          showNow={false}
          showTime
          style={style}
          suffixIcon={suffixIcon}
          variant="borderless"
          {...(datePickerProps || {})}
          popupStyle={popupStyle as RangePickerProps['popupStyle']}
        />
      </DateLabel>
      {onClearReturnDate && <ClearReturnDateButton onClear={onClearReturnDate} />}
      <DateInputGlobalStyles />
    </WrapperDateInput>
  )
}
