import styled, { css } from 'styled-components'

import { captionSmallStyles } from '@styles/typography/caption'
import { textLargeRegularStyles } from '@styles/typography/text'

//!!!check all date pickers after changing the styles
export const datePickerStyles = css`
  & {
    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn {
      border-radius: 50% !important;
    }

    .ant-picker-range-wrapper,
    .ant-picker-panel-container,
    .ant-picker-panel-container
      :where(
        .ant-picker-panel-layout,
        .ant-picker-panel-layout > div,
        .ant-picker-panels,
        .ant-picker-panel,
        .ant-picker-date-panel,
        .ant-picker-datetime-panel
      ) {
      height: inherit;
      width: inherit;
    }

    .ant-picker-range-wrapper .ant-picker-panel-layout > div,
    .ant-picker-panel-container .ant-picker-panel-layout > div {
      ${p => p.theme.mediaQueries.mobile} {
        display: grid;
        grid-template-rows: 1fr 94px;
      }
    }

    .ant-picker-range-wrapper .ant-picker-range-arrow {
      display: none;
    }

    .ant-picker-panel-container {
      display: flex;
      flex-direction: column;
      border-radius: ${p => p.theme.borderRadius.default};
      box-shadow: ${p => p.theme.shadows.big};
      background: ${p => p.theme.colors.backgroundMain};

      .ant-picker-panel-layout {
        .ant-picker-panel {
          .ant-picker-date-panel {
            width: auto;

            .ant-picker {
              &-header {
                padding: 24px;
                border-bottom: none;
                background: ${p => p.theme.colors.backgroundMain};

                &-super-next-btn,
                &-super-prev-btn {
                  display: none;
                }

                &-view {
                  pointer-events: none;

                  .ant-picker-month-btn,
                  .ant-picker-year-btn {
                    text-transform: uppercase;
                    font-weight: 700;
                    color: ${p => p.theme.colors.fontSecondary};
                  }
                }
              }

              &-body {
                height: calc(100% - 88px);
                background: ${p => p.theme.gradient.grayToWhite}, ${p => p.theme.colors.backgroundMain};
                padding: 12px 24px 24px 24px;

                .ant-picker-content {
                  height: 100%;
                  width: 100%;

                  thead tr,
                  tbody :where(.ant-picker-cell, .ant-picker-cell-inner) {
                    width: 50px;
                    height: 50px;
                  }

                  th {
                    width: auto;
                    height: 50px;
                  }

                  @media all and (max-width: 398px) {
                    thead tr th,
                    tbody :where(.ant-picker-cell, .ant-picker-cell-inner) {
                      width: calc(100% / 7);
                    }

                    tbody :where(.ant-picker-cell, .ant-picker-cell-inner) {
                      height: calc((100vw - 48px) / 7);
                    }

                    tbody .ant-picker-cell-inner {
                      width: 100%;
                    }
                  }

                  thead tr th {
                    ${captionSmallStyles};
                    color: ${p => p.theme.colors.fontSecondary};
                  }

                  tbody {
                    .ant-picker-cell {
                      ${textLargeRegularStyles};
                      background: none;
                      padding: 0;

                      &:after {
                        border-color: ${p => p.theme.colors.primary};
                      }

                      &:not(.ant-picker-cell-in-view),
                      &.ant-picker-cell-disabled {
                        opacity: 0.25;
                      }

                      &-selected .ant-picker-cell-inner,
                      &-range-end .ant-picker-cell-inner,
                      &-range-start .ant-picker-cell-inner {
                        background: ${p => p.theme.colors.primary};

                        &:hover {
                          color: ${p => p.theme.colors.white};
                        }
                      }

                      &-inner {
                        margin: auto;
                        display: grid;
                        place-content: center;
                        border-radius: 50%;
                        position: relative;
                        transition: none;

                        &:hover {
                          background: ${p => p.theme.gradient.grayToWhite}, ${p => p.theme.colors.backgroundMain};
                          color: ${p => p.theme.colors.primary};

                          ${p => p.theme.mediaQueries.mobile} {
                            background: none;
                            color: initial;
                          }

                          &:after {
                            content: '';
                            position: absolute;
                            inset: 0;
                            z-index: 1;
                            background: ${p => p.theme.colors.backgroundBlueContainer};
                            border-radius: 50%;
                          }
                        }
                      }

                      &-today {
                        .ant-picker-cell-inner:before {
                          border-color: ${p => p.theme.colors.primary};
                        }
                      }

                      &-in-range,
                      &-range-start:not(.ant-picker-cell-range-start-single),
                      &-range-end:not(.ant-picker-cell-range-end-single) {
                        &::before {
                          background: ${p => p.theme.colors.backgroundRangePicker};
                        }
                      }
                    }

                    &-disabled {
                      &:before {
                        background: none;
                      }
                    }

                    &-selected .ant-picker-cell-inner {
                      background: ${p => p.theme.colors.backgroundBlueContainer};
                      color: ${p => p.theme.colors.primary};
                    }

                    &-selected .ant-picker-cell-inner {
                      width: 50px;
                      height: 50px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      background: ${p => p.theme.colors.backgroundBlueContainer};
                      color: ${p => p.theme.colors.primary};
                    }

                    &-range-start,
                    &-range-end {
                      &:before {
                        background: none;
                      }

                      div {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50% !important;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.01em;
                        background: ${p => p.theme.colors.backgroundBlueContainer} !important;
                        color: ${p => p.theme.colors.primary} !important;
                      }
                    }

                    &-today {
                      font-weight: 700;

                      .ant-picker-cell-inner:before {
                        border-color: ${p => p.theme.colors.primary};
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ant-picker-footer,
        .ant-picker-footer-extra {
          border: none;
        }
      }

      ${p => p.theme.mediaQueries.mobile} {
        border-radius: 0;
        display: grid;
        grid-template-rows: 112px 1fr;

        .ant-picker-panel {
          overflow-y: auto;
        }

        .ant-picker-panel-layout {
          overflow-y: auto;

          & > div {
            overflow-y: hidden;
          }
        }
      }
    }
  }
`

//hide second month in range picker
export const rangePickerTightStyles = css`
  .ant-picker-range-wrapper {
    .ant-picker-panels > *:last-child {
      display: none !important;
    }

    .ant-picker-panels > *:first-child .ant-picker-header-next-btn {
      visibility: visible !important;
    }
  }
`

export const StyledDatePicker = styled.div`
  .ant-picker-dropdown {
    ${datePickerStyles};
  }
`
